import types from './action'

const initialState = {
  loading: false,
  isCreated: false,
  searchResults: [],
}

export default function taxonomyReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case types.RESET_STATE:
      return { ...initialState }
    default:
      return state
  }
}
