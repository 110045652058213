/* eslint-disable */
import React from 'react'
import { notification, Button } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getQuoteTopicList } from 'services/common'
import actions from './action'
import {
  createQuote,
  getQuoteList,
  getQuoteByUuid,
  deleteQuoteByUuid,
  updateQuote,
} from 'services/quote'

export function* getTopics() {
  try {
    const result = yield call(getQuoteTopicList)
    const { data } = result
    if (result.status === 200) {
      yield put({
        type: 'quote/SET_STATE',
        payload: {
          editLecture: '',
          topics: data.topic,
        },
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: 'Error Occured while getting Topics',
    })
  }
}

export function* createQuoteSaga({ payload }) {
  try {
    yield put({
      type: 'quote/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const result = yield call(createQuote, payload)
    if (result.status === 200) {
      notification.success({
        message: 'Success',
        description: 'Quote is created successfully',
      })
      console.log(result.data)
      yield put({
        type: 'quote/SET_STATE',
        payload: {
          isQuoteCreated: true,
          isDeleted: false,
          isUpdated: false,
          quotes: [],
          uuid: result.data.data.uuid,
          loading: false,
        },
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'quote/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* getQuoteListSaga(payload) {
  try {
    const { page } = payload
    const result = yield call(getQuoteList, page)
    const { data } = result
    if (result.status === 200) {
      yield put({
        type: 'quote/SET_STATE',
        payload: {
          quotes: data.data,
          totalQuotes: data.meta.total,
          editQuote: '',
          isQuoteCreated: false,
          isDeleted: false,
          isUpdated: false,
          uuid: null,
          loading: false,
        },
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'quote/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* getQuoteByUuidSaga(body) {
  try {
    const result = yield call(getQuoteByUuid, body)
    const { data } = result
    if (result.status === 200) {
      yield put({
        type: 'quote/SET_STATE',
        payload: {
          editQuote: data.data,
          isQuoteCreated: false,
          isDeleted: false,
          isUpdated: false,
          uuid: null,
          loading: false,
        },
      })
    }
  } catch (err) {
    const key = `${Date.now()}`
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.`,
      key,
      btn: (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            notification.close(key)
            document.location.reload()
          }}
        >
          Reload
        </Button>
      ),
      duration: 0,
    })
    yield put({
      type: 'quote/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* deleteQuoteByUuidSaga(payload) {
  try {
    const { uuid } = payload
    const result = yield call(deleteQuoteByUuid, uuid)
    if (result.status === 200) {
      yield put({
        type: 'quote/SET_STATE',
        payload: {
          isDeleted: true,
          isQuoteCreated: false,
          isUpdated: false,
          uuid: null,
          loading: false,
        },
      })
      notification.success({
        message: 'Success',
        description: 'Quote is Deleted successfully',
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'quote/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* updateQuoteSaga(payload) {
  try {
    yield put({
      type: 'quote/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const { body, uuid } = payload.payload
    const result = yield call(updateQuote, uuid, { data: body })
    if (result.status === 200) {
      yield put({
        type: 'quote/SET_STATE',
        payload: {
          isUpdated: true,
          isQuoteCreated: false,
          isDeleted: false,
          uuid: null,
          loading: false,
        },
      })
      notification.success({
        message: 'Success',
        description: 'Quote is updated successfully',
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'quote/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TOPICS, getTopics),
    takeEvery(actions.CREATE_QUOTE, createQuoteSaga),
    takeEvery(actions.GET_QUOTES, getQuoteListSaga),
    takeEvery(actions.GET_QUOTE_BY_ID, getQuoteByUuidSaga),
    takeEvery(actions.DELETE_QUOTE_BY_ID, deleteQuoteByUuidSaga),
    takeEvery(actions.UPDATE_QUOTE, updateQuoteSaga),
  ])
}
