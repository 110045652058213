import types from './action'

const initialState = {
  loading: false,
  error: '',
}

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case types.SEND_NOTIFICATION:
    case types.SET_STATE:
      return { ...state, ...action.payload }

    case types.RESET_STATE:
      return { ...state }
    default:
      return state
  }
}
