import axios from 'axios'
import { serverAddress } from './config'

export async function getKirtanList(page) {
  const pageNumber = page || 1
  const url = `${serverAddress}/api/kirtan/list?page=${pageNumber}`
  return axios.get(url)
}

export async function createKirtan(body) {
  const url = `${serverAddress}/api/kirtan/create`
  return axios.post(url, body)
}

export async function deleteKirtanByUuid(uuid) {
  const url = `${serverAddress}/api/kirtan/${uuid}`
  return axios.delete(url)
}

export async function updateKirtan(uuid, body) {
  const url = `${serverAddress}/api/kirtan/update`
  return axios.post(url, body)
}

export async function getLectureByUuid(request) {
  const body = request.payload
  const url = `${serverAddress}/api/kirtan/${body.uuid}`
  return axios.get(url, body)
}
