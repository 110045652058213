import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getSocialPosts, approveSocialPost } from 'services/social'
import actions from './action'

export function* getSocialPostsSaga(payload) {
  try {
    const result = yield call(getSocialPosts, payload)
    const { data } = result

    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          socialPosts: data.data,
          total: data.meta.total,
          updated: false,
        },
      })
    }
  } catch (err) {
    console.log(err)
    notification.warning({
      message: 'Error',
      description: 'Some Error Occured',
    })
  }
}

export function* approveSocialPostSaga(body) {
  try {
    const result = yield call(approveSocialPost, body)
    console.log(body)
    const { data } = result
    if (result.status === 200) {
      notification.success({
        message: 'Success',
      })
      let ar = body.socialPosts || []
      let index = ar
        .map(function (e) {
          return e.uuid
        })
        .indexOf(body.body.uuid)
      if (index > -1) {
        ar.splice(index, 1)
      }
      yield put({
        type: actions.SET_STATE,
        payload: {
          updated: true,
          socialPosts: ar,
        },
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: 'Error Occured while while updating',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SOCIAL_POSTS, getSocialPostsSaga),
    takeEvery(actions.APPROVE_SOCIAL_POST, approveSocialPostSaga),
  ])
}
