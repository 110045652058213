import types from './action'

const initialState = {
  loading: false,
  videos: [],
  totalVideos: '',
  error: '',
  isDeleted: false,
}

export default function videoReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_STATE: {
      return { ...state, ...action.payload, reset: false }
    }
    case types.RESET_STATE:
      return { ...state, reset: true }
    default:
      return state
  }
}
