const actions = {
  SET_STATE: 'lecture/SET_STATE',
  GET_LECTURES: 'lecture/GET_LECTURES',
  GET_KEYWORDS: 'lecture/GET_KEYWORDS',
  GET_EVENTS: 'lecture/GET_EVENTS',
  GET_LOCATIONS: 'lecture/GET_LOCATIONS',
  CREATE_LECTURE: 'lecture/CREATE_LECTURE',
  DELETE_LECTURE: 'lecture/DELETE_LECTURE',
  UPDATE_LECTURE: 'lecture/UPDATE_LECTURE',
  GET_LECTURE_BY_ID: 'lecture/GET_LECTURE_BY_ID',
  GET_AUDIO_CLIPS: 'lecture/GET_AUDIO_CLIPS',
  RESET_STATE: 'lecture/RESET_STATE',
}

export default actions
