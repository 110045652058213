import axios from 'axios'
import { serverAddress, userIndex } from './config'
/* eslint-disable */

export async function getOrders(payload) {
  const url = `${serverAddress}/api/order/all`
  return axios
    .get(url)
    .then((response) => {
      if (response && response.data) {
        return response
      }
      return false
    })
    .catch((error) => {
      return error
    })
}

export async function updateOrder(payload) {
  console.log(payload)
  const url = `${serverAddress}/api/order/update`
  return axios
    .post(url, payload)
    .then((response) => {
      if (response && response.data) {
        return response
      }
      return false
    })
    .catch((error) => {
      return error
    })
}
