import React from 'react'
import { notification, Button } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import {
  getKirtanList,
  createKirtan,
  deleteKirtanByUuid,
  updateKirtan,
  getLectureByUuid,
} from 'services/kirtan'
import actions from './action'

export function* createKirtanSaga({ payload }) {
  try {
    yield put({
      type: 'kirtan/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const result = yield call(createKirtan, payload)
    if (result.status === 200) {
      notification.success({
        message: 'Success',
        description: 'Kirtan is created successfully',
      })
      yield put({
        type: 'kirtan/SET_STATE',
        payload: {
          isKirtanCreated: true,
          isDeleted: false,
          isUpdated: false,
          kirtans: [],
          uuid: result.data.data.uuid,
          loading: false,
        },
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'kirtan/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* getKirtanListSaga(payload) {
  try {
    const { page } = payload
    const result = yield call(getKirtanList, page)
    const { data } = result
    console.log('result =====>>>', result)
    if (result.status === 200) {
      yield put({
        type: 'kirtan/SET_STATE',
        payload: {
          kirtans: data.data,
          totalKirtans: data.meta.total,
          isKirtanCreated: false,
          isDeleted: false,
          isUpdated: false,
          editKirtan: '',
          uuid: null,
          loading: false,
        },
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'kirtan/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* getKirtanByUuidSaga(body) {
  try {
    const result = yield call(getLectureByUuid, body)
    const { data } = result
    console.log('result uuid =====>>>', result)
    if (result.status === 200) {
      yield put({
        type: 'kirtan/SET_STATE',
        payload: {
          editKirtan: data.data,
          isKirtanCreated: false,
          isDeleted: false,
          isUpdated: false,
          uuid: null,
          loading: false,
        },
      })
    }
  } catch (err) {
    const key = `${Date.now()}`
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.`,
      key,
      btn: (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            notification.close(key)
            document.location.reload()
          }}
        >
          Reload
        </Button>
      ),
      duration: 0,
    })
    yield put({
      type: 'kirtan/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* deleteKirtanByUuidSaga(payload) {
  try {
    const { uuid } = payload
    const result = yield call(deleteKirtanByUuid, uuid)
    if (result.status === 200) {
      yield put({
        type: 'kirtan/SET_STATE',
        payload: {
          isKirtanCreated: false,
          isDeleted: true,
          isUpdated: false,
          uuid: null,
          loading: false,
        },
      })
      notification.success({
        message: 'Success',
        description: 'Kirtan is Deleted successfully',
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'kirtan/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* updateKirtanSaga(payload) {
  try {
    yield put({
      type: 'kirtan/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const { body, uuid } = payload.payload
    const result = yield call(updateKirtan, uuid, body)
    if (result.status === 200) {
      yield put({
        type: 'kirtan/SET_STATE',
        payload: {
          isKirtanCreated: false,
          isDeleted: false,
          isUpdated: true,
          uuid: null,
          loading: false,
        },
      })
      notification.success({
        message: 'Success',
        description: 'Kirtan is updated successfully',
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'kirtan/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_KIRTAN, createKirtanSaga),
    takeEvery(actions.GET_KIRTAN, getKirtanListSaga),
    takeEvery(actions.GET_KIRTAN_BY_ID, getKirtanByUuidSaga),
    takeEvery(actions.DELETE_KIRTAN_BY_ID, deleteKirtanByUuidSaga),
    takeEvery(actions.UPDATE_KIRTAN, updateKirtanSaga),
  ])
}
