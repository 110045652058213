import types from './action'

const initialState = {
  loading: false,
  lectures: [],
  totalLectures: '',
  topics: [],
  events: [],
  locations: [],
  authors: [],
  error: '',
  isUpdated: false,
  isLectureCreated: false,
  isDeleted: false,
  editLecture: '',
  audioClips: [],
  reset: false,
}

export default function lectureReducer(state = initialState, action) {
  console.log(action)
  switch (action.type) {
    case types.SET_STATE: {
      return { ...state, ...action.payload, reset: false }
    }
    case types.RESET_STATE:
      return { ...state, editLecture: '', reset: true }
    default:
      return state
  }
}
