import axios from 'axios'
import { serverAddress } from './config'

export async function getTopicList() {
  const url = `${serverAddress}/api/topic/`
  return axios
    .get(url)
    .then((response) => {
      if (response && response.data) {
        return response
      }
      return false
    })
    .catch((error) => {
      return error
    })
}

export async function getQuoteTopicList() {
  const url = `${serverAddress}/api/quotetopiclist/`
  return axios
    .get(url)
    .then((response) => {
      if (response && response.data) {
        return response
      }
      return false
    })
    .catch((error) => {
      return error
    })
}

export async function getEventList() {
  const url = `${serverAddress}/api/event/`
  return axios
    .get(url)
    .then((response) => {
      if (response && response.data) {
        return response
      }
      return false
    })
    .catch((error) => {
      return error
    })
}

export async function getLocationList() {
  const url = `${serverAddress}/api/location/`
  return axios
    .get(url)
    .then((response) => {
      if (response && response.data) {
        return response
      }
      return false
    })
    .catch((error) => {
      return error
    })
}

export async function getKeywords(type) {
  const url = `${serverAddress}/api/keywords?types=${
    type
      ? type
      : 'LectureTopic,Location,Artist/Author,Event,QuoteAuthor,QuoteTopic,Tags,Translation,Gallery,Service,spLocation,spLectureTopic'
  }`
  return axios
    .get(url)
    .then((response) => {
      if (response && response.data) {
        return response
      }
      return false
    })
    .catch((error) => {
      return error
    })
}
