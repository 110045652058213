import Amplify, { Auth } from 'aws-amplify'

Amplify.configure({
  Auth: {
    region: 'eu-central-1',

    userPoolId: 'eu-central-1_LJiTGPicM',

    userPoolWebClientId: '2pggi2h07uhf4skekoscm9l7fr',

    mandatorySignIn: false,

    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
})

export function signUp(body) {
  return Auth.signUp({
    username: body.email,
    password: body.password,
    attributes: {
      email: body.email,
      name: body.name,
      family_name: body.family_name,
      'custom:uuid': body.uuid,
    },
  })
}

export function resetPasswordWithCode(username, code, new_password) {
  return Auth.forgotPasswordSubmit(username, code, new_password)
    .then((data) => {
      return data
    })
    .catch((err) => {
      return err
    })
}

export function getCurrentUser() {
  return Auth.currentAuthenticatedUser()
}

export function signIn(body) {
  return Auth.signIn(body.email, body.password)
}

export function completeNewPassword(body) {
  return Auth.completeNewPassword(body.user, body.newPassword)
}

export function resendotp(body) {
  return Auth.resendSignUp(body.email)
}
export function signOut(username, password) {
  return Auth.signOut()
    .then((data) => {
      return data
    })
    .then((error) => {
      return error
    })
}

export function forgotPassword(email) {
  return Auth.forgotPassword(email)
    .then((data) => {
      return data
    })
    .catch((err) => {
      return err
    })
}

export function checkUser() {
  return Auth.currentAuthenticatedUser()
    .then((user) => user)
    .catch((err) => err)
}

export function verifyOTP(body) {
  return Auth.confirmSignUp(body.username, body.code, {
    forceAliasCreation: true,
  })
}

export function getToken() {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((data) => {
        const { signInUserSession } = data
        const token =
          signInUserSession && signInUserSession.idToken && signInUserSession.idToken.jwtToken
            ? signInUserSession.idToken.jwtToken
            : null
        resolve(token)
      })
      .catch((err) => reject(err))
  })
}
