import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getOrders, updateOrder } from 'services/orders'
import actions from './action'

export function* getOrdersSaga(payload) {
  try {
    const result = yield call(getOrders, payload)

    const { data } = result

    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          orders: data.data,
        },
      })
    }
  } catch (err) {
    console.log(err)
    notification.warning({
      message: 'Error',
      description: 'Some Error Occured',
    })
  }
}

export function* updateOrderSaga(body) {
  try {
    const result = yield call(updateOrder, { ...body.payload })
    const { data } = result
    if (result.status === 200) {
      notification.success({
        message: 'Success',
        description: 'Order updated successfully',
      })
      yield put({
        type: actions.SET_STATE,
        payload: {},
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: 'Error Occured while while updating',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ORDERS, getOrdersSaga),
    takeEvery(actions.UPDATE_ORDER, updateOrderSaga),
  ])
}
