import axios from 'axios'
import { serverAddress } from './config'
/* eslint-disable */

export async function getLectureList(
  page,
  date,
  createdDateSort,
  author,
  topic,
  year,
  language,
  location,
  event,
  translation,
  canto,
  chapter,
  verse,
) {
  const pageNumber = page || 1
  const dateNow = date || null
  const createdDateSorting = createdDateSort || null
  const url =
    serverAddress +
    '/api/lecture/list?&page=' +
    pageNumber +
    (dateNow ? '&lectureDate=' + date : '') +
    (createdDateSorting && createdDateSorting === 'desc' ? '&sort=lectureDate' : '') +
    (author && author === 'sp' ? `&author=sp` : '&author=ns') +
    (!!topic && topic !== '' ? `&topic.en=${topic}` : '') +
    (!!year && year !== '' ? `&lectureDateYear=${year}` : '') +
    (location ? '&location.en=' + location : '') +
    (event ? '&en.event=' + event : '') +
    (translation ? '&en.translation=' + translation : '') +
    (canto ? '&part=' + canto : '') +
    (chapter ? '&chapter=' + chapter : '') +
    (verse ? '&verse=' + verse : '')
  return axios.get(url)
}

export async function createLecture(body) {
  const url = `${serverAddress}/api/lecture/create/`
  return axios.post(url, body)
}

export async function deleteLectureByUuid(uuid, author) {
  const url = `${serverAddress}/api/lecture/${uuid}${
    author && author === 'sp' ? `?author=sp` : '?author=ns'
  }`
  return axios.delete(url)
}

export async function updateLecture(body) {
  console.log('value in api ====>>>', body)
  const url = `${serverAddress}/api/lecture/update`
  return axios.post(url, body)
}

export async function getLectureByUuid(request) {
  const body = request.payload
  const { author, uuid } = body
  const url = `${serverAddress}/api/lecture/${uuid}?author=${
    author && author === 'sp' ? 'sp' : 'ns'
  }`
  return axios.get(url, body)
}

export async function getAudioClipList(page, lectureId) {
  console.log(lectureId)
  const pageNumber = page || 1
  const url = serverAddress + '/api/lecture/listAudioClips?lectureId=' + lectureId
  return axios.get(url)
}
