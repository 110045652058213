import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import blog from './blog/saga'
import lecture from './lecture/saga'
import gallery from './gallery/saga'
import quote from './quote/saga'
import galleryList from './galleryListing/saga'
import kirtan from './kirtan/saga'
import sadhana from './sadhana/saga'
import userProfile from './userProfile/saga'
import taxonomy from './taxonomy/saga'
import comment from './comment/saga'
import orders from './orders/saga'
import videos from './videos/saga'
import notification from './notification/saga'
import playlist from './playlist/saga'
import social from "./social/saga"

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    blog(),
    lecture(),
    gallery(),
    quote(),
    galleryList(),
    kirtan(),
    sadhana(),
    userProfile(),
    taxonomy(),
    comment(),
    orders(),
    videos(),
    notification(),
    playlist(),
    social()
  ])
}
