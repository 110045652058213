import React from 'react'
import { notification, Button } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { sendNotification } from 'services/notification'
import actions from './action'

export function* sendNotificationSaga({ payload }) {
  try {
    yield put({
      type: 'notification/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const result = yield call(sendNotification, payload)
    if (result.status === 200) {
      notification.success({
        message: 'Success',
        description: 'Notification sent successfully',
      })
      yield put({
        type: 'notification/SET_STATE',
        payload: {
          loading: false,
        },
      })
    }
    else if(result === 'The user is not authenticated'){
      notification.error({
        message: 'Session Timed Out',
        description: `Please login to continue`,
        duration: 0,
      })
      yield put({
        type: 'user/LOGOUT',
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'notification/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.SEND_NOTIFICATION, sendNotificationSaga)])
}
