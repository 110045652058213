import React from 'react'
import classNames from 'classnames'
import styles from './style.module.scss'

const Loader = ({ spinning = true, fullScreen, text }) => (
  <>
    <div
      className={classNames(styles.loader, {
        [styles.hidden]: !spinning,
        [styles.fullScreen]: fullScreen,
      })}
    />
    {text ? <div style={{ textAlign: 'center' }}>{text}</div> : null}
  </>
)

export default Loader
