import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import { login, currentAccount, logout } from 'services/user'
import {
  signIn,
  completeNewPassword,
  getCurrentUser,
  signOut,
  forgotPassword,
  resetPasswordWithCode,
} from '../../services/awsAmplify'
import actions from './actions'
import localstorage from '../../services/localStorage'

let user

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      resetPasswordSuccess: false,
    },
  })
  try {
    const success = yield call(signIn, { email, password })
    console.log(success)
    if (success) {
      if (success.challengeName === 'NEW_PASSWORD_REQUIRED') {
        user = success
        yield put({
          type: 'user/SET_NEW_PASSWORD_REQUEST',
          payload: {
            loading: false,
          },
        })
      } else {
        notification.success({
          message: 'Logged In',
          description: `You have successfully logged in to Admin Site of Niranjan Swami's website!`,
        })
        localstorage.setItem('cognitoUser', success)
        yield put({
          type: 'user/LOGIN_SUCCESS',
          payload: { ...success, loading: true },
        })
        window.location.reload(false)
      }
    }
  } catch (err) {
    console.log(err)
    notification.warning({
      message: 'Error',
      description: err.message ? err.message : 'Some error occurred',
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        error: err.message ? err.message : err,
      },
    })
  }
}

export function* setNewPassword({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      resetPasswordSuccess: false,
    },
  })
  const success = yield call(completeNewPassword, { user: user, newPassword: payload })
  if (success) {
    notification.success({
      message: 'Successfully Changed Password',
      description: 'You have successfully set your password.',
    })
    localstorage.setItem('cognitoUser', success)
    yield put({
      type: 'user/LOGIN_SUCCESS',
      payload: success,
    })
    window.location.reload(false)
  }
}

export function* requestOtp({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      resetPasswordSuccess: false,
    },
  })
  const email = payload.email
  console.log('EMAIL>>>>>>>>', email)
  const success = yield call(forgotPassword, email)
  console.log(success)
  if (success) {
    console.log('aya yaha')
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        resetPassword: true,
      },
    })
  }
}

export function* resetPassword({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      resetPasswordSuccess: false,
    },
  })
  try {
    const { username, code, new_password } = payload
    console.log(payload)
    const success = yield call(resetPasswordWithCode, username, code, new_password)
    console.log(success)

    notification.success({
      message: 'Successfully Changed Password',
      description:
        'You have successfully reset your password. Please login with your new password!',
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        resetPassword: false,
        resetPasswordSuccess: true,
      },
    })
  } catch (err) {
    console.log(err)
    notification.warning({
      message: 'Error',
      description: err.message ? err.message : 'Some error occurred',
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        error: err.message ? err.message : err,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      resetPasswordSuccess: false,
    },
  })
  const response = yield call(currentAccount)
  console.log('RESPONSE ======>', response)
  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        role: 'admin',
        authorized: true,
      },
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        authorized: false,
        loading: false,
      },
    })
  }
}

export function* LOGOUT() {
  yield call(signOut)
  localstorage.setItem('cognitoUser', null)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      authorized: false,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOGIN, LOGIN),
    takeLatest(actions.SET_NEW_PASSWORD, setNewPassword),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeLatest(actions.REQUEST_OTP, requestOtp),
    takeLatest(actions.RESET_PASSWORD, resetPassword),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
