export default {
    getItem: key => {
      try {
        const value = localStorage.getItem(key);
        return JSON.parse(value); // if found value returned otherwise returns null
      } catch (error) {
        console.log(error);
        return error;
      }
    },
  
    setItem: (key, value) => {
      try {
        return localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        console.log(error);
        return error;
      }
    },
  
    removeItem: key => {
      try {
        return localStorage.removeItem(key);
      } catch (error) {
        console.log(error);
        return error;
      }
    },
  
    clearStorage: () => {
      try {
        return localStorage.clear();
      } catch (error) {
        console.log(error);
        return error;
      }
    },
  
    getUserData: () => {
      let cognitoUser = null;
      cognitoUser = localStorage.getItem("cognitoUser");
      cognitoUser = JSON.parse(cognitoUser);
      if (cognitoUser && cognitoUser.userId && cognitoUser.email) {
        return {
          userId: cognitoUser.userId,
          email: cognitoUser.email
        };
      }
      return null;
    },
  
    loggedInSuccess: userData => {
      localStorage.setItem(
        "cognitoUser",
        JSON.stringify({
          userId: userData["custom:uuid"],
          email: userData.email
        })
      );
    },
  
    
  };
  