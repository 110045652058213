import React from 'react'
import { Spin } from 'antd'

const LoadingScreen = (props) => (
  <div>
    <div
      style={{
        textAlign: 'center',
        margin: '50px auto',
        width: '200px',
        padding: '24px',
        backgroundColor: 'white',
        border: '1px solid #e8e8e8',
      }}
    >
      <Spin size="large" />

      <p style={{ marginTop: '12px' }}>{props.message ? props.message : 'Saving Changes'}</p>
    </div>
  </div>
)

export default LoadingScreen
