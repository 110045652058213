import axios from 'axios'
import { serverAddress } from './config'
/* eslint-disable */

export async function getVideoList(page, videoType) {
  const pageNumber = page || 1

  const url =
    serverAddress +
    '/api/video?page=' +
    pageNumber +
    (videoType ? `&type=${videoType}` : '&missing=type')
  return axios.get(url)
}

export async function deleteVideoByUuid(uuid) {
  const url = `${serverAddress}/api/video/delete/${uuid}`
  return axios.delete(url)
}
