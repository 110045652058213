import { notification } from 'antd'
import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects'
import { getVideoList, deleteVideoByUuid } from 'services/videos'
import actions from './action'

export function* deleteVideoById(payload) {
  try {
    yield put({
      type: 'videos/SET_STATE',
      payload: {
        isDeleted: false,
        loading: true,
      },
    })
    const { id } = payload
    const result = yield call(deleteVideoByUuid, id)
    if (result.status === 200) {
      yield put({
        type: 'videos/SET_STATE',
        payload: {
          isDeleted: true,
          loading: false,
        },
      })
      notification.success({
        message: 'Success',
        description: 'Video is Deleted successfully',
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'videos/SET_STATE',
      payload: {
        isDeleted: false,
        loading: false,
      },
    })
  }
}

export function* getVideoListSaga(payload) {
  try {
    const { page, videoType } = payload
    yield put({
      type: 'videos/SET_STATE',
      payload: {
        loading: true,
        videos: [],
      },
    })
    const result = yield call(getVideoList, page, videoType)

    if (result.status === 200) {
      const { data } = result
      yield put({
        type: 'videos/SET_STATE',
        payload: {
          videos: data.data,
          totalVideos: data.meta.total,
          loading: false,
        },
      })
    }
  } catch (err) {
    console.log(err)
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'videos/SET_STATE',
      payload: {
        isDeleted: false,
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.DELETE_VIDEO, deleteVideoById)])
  yield all([takeEvery(actions.GET_VIDEOS, getVideoListSaga)])
}
