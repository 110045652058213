/* eslint-disable */
import axios from 'axios'
import { serverAddress } from './config'

export async function createQuote(body) {
  const url = `${serverAddress}/api/quote/create/`
  return axios.post(url, body)
}

export async function getQuoteList(page) {
  const pageNumber = page || 1
  const url = serverAddress + '/api/quote/list?page=' + pageNumber
  return axios.get(url)
}

export async function getQuoteByUuid(request) {
  const body = request.payload
  const url = `${serverAddress}/api/quote/${body.uuid}`
  return axios.get(url, body)
}

export async function deleteQuoteByUuid(uuid) {
  const url = `${serverAddress}/api/quote/${uuid}`
  return axios.delete(url)
}

export async function updateQuote(uuid, body) {
  const url = `${serverAddress}/api/quote/update`
  return axios.post(url, body)
}
