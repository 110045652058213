import { notification } from 'antd'
import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects'
import {
  getMainGallery,
  createMainGallery,
  removeMainGallery,
  searchKeywords,
  updateTaxonomy,
} from 'services/galleryList'
import actions from './action'

export function* createTaxonomySaga(payload) {
  try {
    const { body } = payload
    const result = yield call(createMainGallery, body)
    if (result.status === 200) {
      yield put({
        type: 'taxonomy/SET_STATE',
        payload: {
          isCreated: true,
          isDeleted: false,
          loading: true,
          isUpdated: false,
        },
      })
      notification.success({
        message: 'Success',
        description: ' Taxonomy has been created successfully',
      })
    }
  } catch (err) {
    notification.warning({
      message: 'Error',
      description: 'Some Error Occured While Creating Taxonomy',
    })
  }
}

export function* updateTaxonomySaga(payload) {
  try {
    const { body } = payload
    const result = yield call(updateTaxonomy, body)
    if (result.status === 200) {
      yield put({
        type: 'taxonomy/SET_STATE',
        payload: {
          isCreated: false,
          isDeleted: false,
          loading: false,
          isUpdated: true,
        },
      })
      notification.success({
        message: 'Success',
        description: ' Taxonomy has been updated successfully',
      })
    }
  } catch (err) {
    notification.warning({
      message: 'Error',
      description: 'Some Error Occured While Creating Taxonomy',
    })
  }
}

export function* removeTaxonomySaga(payload) {
  try {
    const { uuid } = payload
    const result = yield call(removeMainGallery, uuid)
    console.log('result ====>>>>>', result)
    if (result.status === 200) {
      yield put({
        type: 'taxonomy/SET_STATE',
        payload: {
          isCreated: false,
          isDeleted: true,
          loading: true,
        },
      })
      notification.success({
        message: 'Success',
        description: 'Taxonomy is deleted Successfully',
      })
    }
  } catch (err) {
    notification.warning({
      message: 'Error',
      description: 'Some Error Occured While Deleting Taxonomy',
    })
  }
}

export function* searchTaxonomySaga(payload) {
  try {
    console.log(payload)
    const { type, lang, keyword } = payload.payload
    const result = yield call(searchKeywords, type, lang, keyword)
    if (result.status === 200) {
      yield put({
        type: 'taxonomy/SET_STATE',
        payload: {
          searchResults: result.data.data,
        },
      })
    }
  } catch (err) {
    notification.warning({
      message: 'Error',
      description: 'Some Error Occured While searching Taxonomy',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_TAXONOMY, createTaxonomySaga),
    takeEvery(actions.REMOVE_TAXONOMY, removeTaxonomySaga),
    takeLatest(actions.SEARCH_TAXONOMY, searchTaxonomySaga),
    takeLatest(actions.EDIT_TAXONOMY, updateTaxonomySaga),
  ])
}
