const actions = {
  SET_STATE: 'blog/SET_STATE',
  CREATE_BLOG: 'blog/CREATE_BLOG',
  CREATE_SCHEDULED_BLOG: 'blog/CREATE_SCHEDULED_BLOG',
  GET_BLOG: 'blog/GET_BLOG',
  CREATE_ERROR: 'blog/CREATE_ERROR',
  GET_LIST: 'blog/GET_LIST',
  GET_SCHEDULED_LIST: 'blog/GET_SCHEDULED_LIST',
  GET_LIST_ERROR: 'blog/GET_LIST_ERROR',
  GET_BLOG_BY_ID: 'blog/GET_BLOG_BY_ID',
  GET_SCHEDULED_BLOG_BY_ID: 'blog/GET_SCHEDULED_BLOG_BY_ID',
  GET_BLOG_BY_ID_ERROR: 'blog/GET_BLOG_BY_ID_ERROR',
  DELETE_BLOG_BY_ID: 'blog/DELETE_BLOG_BY_ID',
  DELETE_SCHEDULED_BLOG_BY_ID: 'blog/DELETE_SCHEDULED_BLOG_BY_ID',
  UPDATE_BLOG: 'blog/UPDATE_BLOG',
  UPDATE_SCHEDULED_BLOG: 'blog/UPDATE_SCHEDULED_BLOG',
  RESET_STATE: 'blog/RESET_STATE',
}

export default actions
