import React from 'react'
import { notification, Button } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getPlaylistByUuid, getPlaylists, createPlaylist, deletePlaylistByUuid } from 'services/playlist'
import actions from './action'

export function* createPlaylistSaga({ payload }) {
  try {
    console.log(payload)
    yield put({
      type: 'playlist/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const result = yield call(createPlaylist, payload)
    if (result.status === 200) {
      notification.success({
        message: 'Success',
        description: 'Playlist is created successfully',
      })
      yield put({
        type: 'playlist/SET_STATE',
        payload: {
          isPlaylistCreated: true,
          isDeleted: false,
          isUpdated: false,
          playlists: [],
          uuid: payload.playlistId,
          loading: false,
        },
      })
    }
  } catch (err) {
    console.log(err)
    notification.error({
      message: 'Error while saving playlist',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'playlist/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* getPlaylistsSaga(payload) {
  try {
    const result = yield call(getPlaylists)
    const { data } = result
    console.log('result =====>>>', result)
    if (result.status === 200) {
      yield put({
        type: 'playlist/SET_STATE',
        payload: {
          playlists: data.data,
          totalPlaylists: data.data.length,
          isPlaylistCreated: false,
          isDeleted: false,
          isUpdated: false,
          editPlaylist: '',
          uuid: null,
          loading: false,
        },
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'playlist/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* getPlaylistByUuidSaga(body) {
  try {
    const result = yield call(getPlaylistByUuid, body)
    const { data } = result
    console.log('result uuid =====>>>', result)
    if (result.status === 200) {
      yield put({
        type: 'playlist/SET_STATE',
        payload: {
          editPlaylist: data.data,
          isPlaylistCreated: false,
          isDeleted: false,
          isUpdated: false,
          uuid: null,
          loading: false,
        },
      })
    }
  } catch (err) {
    const key = `${Date.now()}`
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.`,
      key,
      btn: (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            notification.close(key)
            document.location.reload()
          }}
        >
          Reload
        </Button>
      ),
      duration: 0,
    })
    yield put({
      type: 'playlist/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* deletePlaylistByUuidSaga(payload) {
  try {
    const { playlistId } = payload
    const result = yield call(deletePlaylistByUuid, playlistId)
    if (result.status === 200) {
      const result1 = yield call(getPlaylists)
      const { data } = result1
      if (result1.status === 200) {
        yield put({
          type: 'playlist/SET_STATE',
          payload: {
            playlists: data.data,
            totalPlaylists: data.data.length,
            isPlaylistCreated: false,
            isDeleted: false,
            isUpdated: false,
            editPlaylist: '',
            uuid: null,
            loading: false,
          },
        })
      }
      notification.success({
        message: 'Success',
        description: 'Playlist is Deleted successfully',
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'playlist/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_PLAYLIST, createPlaylistSaga),
    takeEvery(actions.GET_PLAYLIST, getPlaylistsSaga),
    takeEvery(actions.GET_PLAYLIST_BY_ID, getPlaylistByUuidSaga),
    takeEvery(actions.DELETE_PLAYLIST_BY_ID, deletePlaylistByUuidSaga),
    // takeEvery(actions.UPDATE_KIRTAN, updateKirtanSaga),
  ])
}
