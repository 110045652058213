import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'
import { permissions } from './common/permissionModules'
import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })
console.log('permissions>>>>>>>', permissions)
const routes = [
  // System Pages
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
    module: 'login',
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
    module: 'forgot',
  },

  {
    path: '/blog/add-blog-post/:pathParam',
    component: loadable(() => import('pages/blog/add-blog-post')),
    exact: true,
    module: 'blog',
  },
  {
    path: '/blog/add-blog-post-froala/scheduled/:pathParam',
    component: loadable(() => import('pages/blog/add-blog-post-froala')),
    exact: true,
    module: 'blog',
  },
  {
    path: '/blog/add-blog-post-froala/:pathParam',
    component: loadable(() => import('pages/blog/add-blog-post-froala')),
    exact: true,
    module: 'blog',
  },
  {
    path: '/blog/blog-list',
    component: loadable(() => import('pages/blog/list')),
    exact: true,
    module: 'blog',
  },

  // lecture
  {
    path: '/lecture/list',
    component: loadable(() => import('pages/lecture/list')),
    exact: true,
    module: 'lecture',
  },

  {
    path: '/lecture/create/:pathParam',
    component: loadable(() => import('pages/lecture/addlecture')),
    exact: true,
    module: 'lecture',
  },

  {
    path: '/lecture/audioClips',
    component: loadable(() => import('pages/lecture/audioClips')),
    exact: true,
    module: 'lecture',
  },

  //sp lecture
  {
    path: '/splecture/list',
    component: loadable(() => import('pages/spLecture/list')),
    exact: true,
    module: 'spLecture',
  },

  {
    path: '/splecture/create/:pathParam',
    component: loadable(() => import('pages/spLecture/addlecture')),
    exact: true,
    module: 'spLecture',
  },

  // gallery
  {
    path: '/gallery/mainGallery',
    component: loadable(() => import('pages/gallery/mainGallery')),
    exact: true,
    module: 'gallery',
  },

  {
    path: '/gallery/list',
    component: loadable(() => import('pages/gallery/list')),
    exact: true,
    module: 'gallery',
  },

  {
    path: '/gallery/create/:pathParam',
    component: loadable(() => import('pages/gallery/add-gallery')),
    exact: true,
    module: 'gallery',
  },

  {
    path: '/quote/create/:pathParam',
    component: loadable(() => import('pages/quote/addquote')),
    exact: true,
    module: 'quote',
  },

  {
    path: '/quote/list',
    component: loadable(() => import('pages/quote/list')),
    exact: true,
    module: 'quote',
  },

  // kirtan

  {
    path: '/kirtan/list',
    component: loadable(() => import('pages/Kirtan/list')),
    exact: true,
    module: 'kirtan',
  },

  {
    path: '/kirtan/create/:pathParam',
    component: loadable(() => import('pages/Kirtan/add-kirtan')),
    exact: true,
    module: 'kirtan',
  },

  // sadhana

  {
    path: '/sadhana/list',
    component: loadable(() => import('pages/sadhana/list')),
    exact: true,
    module: 'sadhana',
  },

  //user
  {
    path: '/users/list',
    component: loadable(() => import('pages/usersProfile/list')),
    exact: true,
    module: 'siteUser',
  },
  {
    path: '/users/admin/list',
    component: loadable(() => import('pages/usersProfile/admin/list')),
    exact: true,
    module: 'adminUser',
  },
  {
    path: '/users/reset',
    component: loadable(() => import('pages/usersProfile/reset')),
    exact: true,
    module: 'resetUser',
  },
  {
    path: '/users/activity',
    component: loadable(() => import('pages/usersProfile/insights')),
    exact: true,
    module: 'userActivity',
  },

  //taxonomy
  {
    path: '/taxonomy',
    component: loadable(() => import('pages/taxonomy')),
    exact: true,
    module: 'taxonomy',
  },

  //insights
  {
    path: '/insights/lecture',
    component: loadable(() => import('pages/insights/media')),
    exact: true,
    module: 'lecture',
  },
  {
    path: '/insights/playlist',
    component: loadable(() => import('pages/insights/playlist')),
    exact: true,
    module: 'lecture',
  },
  {
    path: '/insights/blog',
    component: loadable(() => import('pages/insights/blog')),
    exact: true,
    module: 'blog',
  },

  //comments
  {
    path: '/comments',
    component: loadable(() => import('pages/comments')),
    exact: true,
    module: 'comment',
  },

  {
    path: '/comments/action/:uuid/:actionType',
    component: loadable(() => import('pages/interactive_email')),
    exact: true,
    module: 'comment',
  },

  {
    path: '/orders',
    component: loadable(() => import('pages/orders')),
    exact: true,
    module: 'order',
  },
  //video
  {
    path: '/videos/create/:pathParam',
    component: loadable(() => import('pages/videos/add-video')),
    exact: true,
    module: 'lecture',
  },
  {
    path: '/videos/list',
    component: loadable(() => import('pages/videos/list')),
    exact: true,
    module: 'lecture',
  },
  {
    path: '/home',
    component: loadable(() => import('pages/home')),
    exact: true,
    module: 'home',
  },
  {
    path: '/announcements',
    component: loadable(() => import('pages/email/send-email')),
    exact: true,
    module: 'email',
  },
  {
    path: '/announcements/addToList',
    component: loadable(() => import('pages/email/add-to-list')),
    exact: true,
    module: 'email',
  },
  {
    path: '/announcements/removeFromList',
    component: loadable(() => import('pages/email/remove-from-list')),
    exact: true,
    module: 'email',
  },
  {
    path: '/announcements/emailTemplates',
    component: loadable(() => import('pages/email/email-templates')),
    exact: true,
    module: 'email',
  },
  {
    path: '/translate',
    component: loadable(() => import('pages/translate')),
    exact: true,
    module: 'translate',
  },
  {
    path: '/notification',
    component: loadable(() => import('pages/notifications')),
    exact: true,
    module: 'notification',
  },
  // Playlist
  {
    path: '/playlist/list',
    component: loadable(() => import('pages/Playlist/list')),
    exact: true,
    module: 'playlist',
  },

  {
    path: '/playlist/create/:pathParam',
    component: loadable(() => import('pages/Playlist/add-playlist')),
    exact: true,
    module: 'playlist',
  },
  {
    path: '/playlist/createExistingMedia/:pathParam',
    component: loadable(() => import('pages/Playlist/add-playlist-existing-media')),
    exact: true,
    module: 'playlist',
  },
  {
    path: '/sankirtana',
    component: loadable(() => import('pages/social')),
    exact: true,
    module: 'social',
  },
]

class Router extends React.Component {
  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/home" />} />
            {routes.map((route) => {
              // if (permissions.isEnabled(route.module)) {
              return (
                <Route
                  path={route.path}
                  component={permissions.isEnabled(route.module) ? route.component : NotFoundPage}
                  key={route.path}
                  exact={route.exact}
                />
              )
              // }
            })}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
