import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getComments, approveComment } from 'services/comments'
import actions from './action'

export function* getCommentsSaga(payload) {
  try {
    const result = yield call(getComments, payload)
    const { data } = result

    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          comments: data.data,
          total: data.meta.total,
          updated: false,
        },
      })
    }
  } catch (err) {
    console.log(err)
    notification.warning({
      message: 'Error',
      description: 'Some Error Occured',
    })
  }
}

export function* approveCommentSaga(body) {
  try {
    const result = yield call(approveComment, body)
    console.log(body)
    const { data } = result
    if (result.status === 200) {
      notification.success({
        message: 'Success',
      })
      let ar = body.comments || []
      let index = ar
        .map(function (e) {
          return e.uuid
        })
        .indexOf(body.body.uuid)
      if (index > -1) {
        ar.splice(index, 1)
      }
      yield put({
        type: actions.SET_STATE,
        payload: {
          updated: true,
          comments: ar,
        },
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: 'Error Occured while while updating',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_COMMENTS, getCommentsSaga),
    takeEvery(actions.APPROVE_COMMENT, approveCommentSaga),
  ])
}
