import React from 'react'

const defaultModules = ['login', 'forgot', 'home', 'translate', 'notification', 'playlist', "social"]
export const permissions = {
  permissionModules: [
    'blog',
    'lecture',
    'spLecture',
    'gallery',
    'quote',
    'kirtan',
    'sadhana',
    'taxonomy',
    'comment',
    'order',
    'siteUser',
    'adminUser',
    'resetUser',
    'userActivity',
    'email',
    'translate',
  ],
  defaultModules,
  isEnabled: (module) => {
    const user = JSON.parse(localStorage.getItem('cognitoUser'))
    const modules =
      user && user.attributes && user.attributes['custom:modules']
        ? JSON.parse(user.attributes['custom:modules'])
        : user &&
          user.challengeParam &&
          user.challengeParam.userAttributes &&
          user.challengeParam.userAttributes['custom:modules']
        ? JSON.parse(user.challengeParam.userAttributes['custom:modules'])
        : []
    if (modules.includes(module) || defaultModules.includes(module)) {
      return true
    }

    return false
  },
}
