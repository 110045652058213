import axios from 'axios'
import { serverAddress } from './config'

export async function createBlogApi(body) {
  const url = `${serverAddress}/api/blog/create/`
  return axios.post(url, body)
}

export async function getBlogList(page, date, status) {
  const pageNumber = page || 1
  const st = status || 'published'
  let url = `${serverAddress}/api/blog/list/?page=${pageNumber}&status=${st}`
  if (date) {
    url = `${serverAddress}/api/blog/list/?page=${pageNumber}&blogDate=${date}&status=${st}`
  }
  return axios.get(url)
}

export async function getBlogByUuid(request) {
  const body = request.payload
  const url = `${serverAddress}/api/blog/${body.uuid}`
  return axios.get(url)
}

export async function deleteBlogByUuid(uuid) {
  const url = `${serverAddress}/api/blog/${uuid}`
  return axios.delete(url)
}

export async function updateBlog(uuid, body) {
  const url = `${serverAddress}/api/blog/update`
  return axios.post(url, body)
}

export async function createScheduledBlogApi(body) {
  const url = `${serverAddress}/api/blog/scheduled/create/`
  return axios.post(url, body)
}

export async function getScheduledBlogList(page, date, status) {
  const pageNumber = page || 1
  const st = status || 'published'
  let url = `${serverAddress}/api/blog/scheduled/list/?page=${pageNumber}&status=${st}`
  if (date) {
    url = `${serverAddress}/api/blog/scheduled/list/?page=${pageNumber}&blogDate=${date}&status=${st}`
  }
  return axios.get(url)
}

export async function getScheduledBlogByUuid(request) {
  const body = request.payload
  const url = `${serverAddress}/api/blog/scheduled/${body.uuid}`
  return axios.get(url)
}

export async function deleteScheduledBlogByUuid(uuid) {
  const url = `${serverAddress}/api/blog/scheduled/${uuid}`
  return axios.delete(url)
}

export async function updateScheduledBlog(uuid, body) {
  const url = `${serverAddress}/api/blog/scheduled/update`
  return axios.post(url, body)
}
