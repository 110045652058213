import axios from 'axios'
import { serverAddress } from './config'

export async function getStaticGallery() {
  const url = `${serverAddress}/api/gallery/getStaticGallery`
  return axios.get(url)
}

export async function createGallery(body) {
  const url = `${serverAddress}/api/gallery/create/`
  return axios.post(url, body)
}

export async function removeGallery(uuid) {
  const url = `${serverAddress}/api/gallery/${uuid}`
  return axios.delete(url)
}

export async function getSubGalleryByGallery(body) {
  const page = body.page || 1
  const url = `${serverAddress}/api/gallery/list/?page=${page}&gallery=${body.gallery}`
  return axios.get(url, body)
}

export async function getGalleryByUuid(request) {
  const body = request.payload
  const url = `${serverAddress}/api/gallery/${body.uuid}`
  return axios.get(url, body)
}

export async function updateGallery(uuid, body) {
  const url = `${serverAddress}/api/gallery/update`
  return axios.post(url, body)
}
