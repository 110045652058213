import axios from 'axios'
import { serverAddress } from './config'

export async function getPlaylists() {
  const url = `${serverAddress}/api/playlist/publicPlaylist/all`
  return axios.get(url)
}

export async function createPlaylist(body) {
  const url = `${serverAddress}/api/playlist/publicPlaylist`
  return axios.post(url, body)
}

export async function deletePlaylistByUuid(uuid) {
  const url = `${serverAddress}/api/playlist/deletePlaylist/17483675-48c2-4170-a02b-b47b4aae3f5b/${uuid}`
  return axios.delete(url)
}

export async function getPlaylistByUuid(request) {
  const body = request.payload
  const url = `${serverAddress}/api/playlist/publicPlaylist/${body.uuid}${
    body.isExistingMedia ? '' : '?admin=true'
  }`
  return axios.get(url, body)
}
