import { permissions } from '../common/permissionModules'
export async function getLeftMenuData() {
  const menu = [
    {
      title: 'BLOG',
      module: 'blog',
      key: 'blog',
      children: [
        {
          title: 'ADD BLOG',
          key: 'blogAddPost',
          url: '/blog/add-blog-post-froala/new',
        },
        {
          title: 'LIST',
          key: 'blogList',
          url: '/blog/blog-list',
        },
      ],
    },
    {
      title: 'PLAYLIST',
      module: 'playlist',
      key: 'playlist',
      children: [
        {
          title: 'LIST',
          key: 'list',
          url: '/playlist/list',
        },
        {
          title: 'ADD PLAYLIST (UPLOAD NEW MEDIA)',
          key: 'addPlaylist',
          url: '/playlist/create/new',
        },
        {
          title: 'ADD PLAYLIST (EXISTING LECTURES)',
          key: 'addPlaylistExistingMedia',
          url: '/playlist/createExistingMedia/new',
        },
      ],
    },
    {
      title: 'LECTURE',
      module: 'lecture',
      key: 'lecture',
      children: [
        {
          title: 'LIST',
          key: 'list',
          url: '/lecture/list',
        },
        {
          title: 'ADD LECTURE',
          key: 'addLecture',
          url: '/lecture/create/new',
        },
      ],
    },
    {
      title: 'SP LECTURE',
      module: 'spLecture',
      key: 'splecture',
      children: [
        {
          title: 'LIST',
          key: 'splist',
          url: '/splecture/list',
        },
        // {
        //   title: 'ADD LECTURE',
        //   key: 'spaddLecture',
        //   url: '/splecture/create/new',
        // },
      ],
    },
    {
      title: 'GALLERY',
      module: 'gallery',
      key: 'gallery',
      children: [
        {
          title: 'LIST',
          key: 'GalleryList',
          url: '/gallery/list',
        },
        {
          title: 'CREATE ALBUM',
          key: 'createImageCollection',
          url: '/gallery/create/new',
        },
      ],
    },
    {
      title: 'QUOTE',
      module: 'quote',
      key: 'quote',
      children: [
        {
          title: 'LIST',
          key: 'QuoteList',
          url: '/quote/list',
        },
        {
          title: 'CREATE QUOTE',
          key: 'createQuote',
          url: '/quote/create/new',
        },
      ],
    },
    {
      title: 'KIRTAN',
      module: 'kirtan',
      key: 'kirtan',
      children: [
        {
          title: 'LIST',
          key: 'KirtanList',
          url: '/kirtan/list',
        },
        {
          title: 'CREATE KIRTAN',
          key: 'createKirtan',
          url: '/kirtan/create/new',
        },
      ],
    },
    {
      title: 'SADHANA',
      module: 'sadhana',
      key: 'sadhana',
      children: [
        {
          title: 'LIST',
          key: 'SadhanaList',
          url: '/sadhana/list',
        },
      ],
    },
    {
      title: 'VIDEO',
      module: 'lecture',
      key: 'video',
      children: [
        {
          title: 'CREATE VIDEO',
          key: 'createVideo',
          url: '/videos/create/new',
        },
        {
          title: 'LIST',
          key: 'listVideo',
          url: '/videos/list',
        },
      ],
    },
    {
      title: 'USERS',
      module: 'users',
      key: 'users',
      children: [
        {
          title: 'SITE USER LIST',
          key: 'siteUser',
          module: 'siteUser',
          url: '/users/list',
        },
        {
          title: 'USER ACTIVITY',
          key: 'userActivity',
          module: 'userActivity',
          url: '/users/activity',
        },
        {
          title: 'ADMIN USER LIST',
          key: 'adminUser',
          module: 'adminUser',
          url: '/users/admin/list',
        },

        {
          title: 'RESET USER',
          key: 'resetUser',
          module: 'resetUser',
          url: '/users/reset',
        },
      ],
    },
    {
      title: 'INSIGHTS',
      module: 'users',
      key: 'insights',
      children: [
        {
          title: 'LECTURE',
          module: 'lecture',
          key: 'lecture',
          url: '/insights/lecture',
        },
        {
          title: 'BLOG',
          module: 'blog',
          key: 'blog',
          url: '/insights/blog',
        },
        {
          title: 'PLAYLIST',
          module: 'lecture',
          key: 'playlist',
          url: '/insights/playlist',
        },
      ],
    },

    {
      title: 'TAXONOMY',
      module: 'taxonomy',
      key: 'taxonomy',
      url: '/taxonomy',
    },
    {
      title: 'COMMENTS',
      module: 'comment',
      key: 'comment',
      url: '/comments',
    },
    {
      title: 'SANKIRTANA',
      module: 'social',
      key: 'social',
      url: '/sankirtana',
    },
    {
      title: 'ORDERS',
      module: 'order',
      key: 'order',
      url: '/orders',
    },
    {
      title: 'ANNOUNCEMENTS',
      module: 'email',
      key: 'email',
      children: [
        {
          title: 'ANNOUNCEMENTS LIST',
          module: 'email',
          key: 'add_to_list',
          url: '/announcements/addToList',
        },
        {
          title: 'EMAIL TEMPLATES',
          module: 'email',
          key: 'email_templates',
          url: '/announcements/emailTemplates',
        },
        {
          title: 'SEND ANNOUNCEMENTS',
          module: 'email',
          key: 'send_email',
          url: '/announcements',
        },
      ],
    },
    {
      title: 'TRANSLATE',
      module: 'translate',
      key: 'translate',
      url: '/translate',
    },
    {
      title: 'NOTIFICATIONS',
      module: 'notification',
      key: 'notification',
      url: '/notification',
    },
  ]
  const ar = menu.filter((a) => {
    if (a.module !== 'users') {
      return permissions.isEnabled(a.module)
    } else if (a.module === 'users') {
      let ar = []
      a.children.map((child) => {
        if (permissions.isEnabled(child.module)) {
          ar.push(child)
        }
      })
      if (ar.length > 0) {
        a.children = ar
        return true
      }
    }
  })
  return ar
}
export async function getTopMenuData() {
  return []
}
