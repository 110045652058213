import types from './action'

const initialState = {
  loading: false,
  users: [],
  userDetails: {},
  discipleData: null,
  userActivity: [],
  mediaActivity: [],
}

export default function userProfileReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_STATE:
      return { ...state, ...action.payload }
    case types.RESET_DISCIPLE_DATA: {
      return { ...state, discipleData: null }
    }
    default:
      return state
  }
}
