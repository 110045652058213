import axios from 'axios'
import { serverAddress } from './config'

export async function getComments(request) {
  console.log('request>>>>>>>>>', request)
  const url = `${serverAddress}/api/comments/list?approved=${request.approved}&page=${request.page}`
  return axios
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        return response
      }
      return false
    })
    .catch((error) => {
      return error
    })
}

export async function approveComment(request) {
  const body = { data: request.body }
  const url = `${serverAddress}/api/comments/update`
  return axios
    .post(url, body)
    .then((response) => {
      if (response.status === 200) {
        return response
      }
      return false
    })
    .catch((error) => {
      return error
    })
}
