import React from 'react'
import { notification, Button } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import {
  createBlogApi,
  getBlogList,
  getBlogByUuid,
  deleteBlogByUuid,
  updateBlog,
  createScheduledBlogApi,
  getScheduledBlogByUuid,
  getScheduledBlogList,
  deleteScheduledBlogByUuid,
  updateScheduledBlog,
} from 'services/blog'
import actions from './action'

export function* createBlogSaga({ payload }) {
  try {
    yield put({
      type: 'blog/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const result = yield call(createBlogApi, payload)
    if (result.status === 200) {
      notification.success({
        message: 'Success',
        description: 'Blog is created successfully',
      })
      yield put({
        type: 'blog/SET_STATE',
        payload: {
          isBlogCreated: true,
          isDeleted: false,
          isUpdated: false,
          blogs: [],
          uuid: result.data.data.uuid,
          loading: false,
        },
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'blog/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* getBlogListSaga(payload) {
  try {
    const { page } = payload
    const { date, status } = payload
    const result = yield call(getBlogList, page, date, status)
    const { data } = result
    if (result.status === 200) {
      yield put({
        type: 'blog/SET_STATE',
        payload: {
          blogs: data.data,
          totalBlogs: data.meta.total,
          isBlogCreated: false,
          isDeleted: false,
          isUpdated: false,
          editBlog: '',
          uuid: null,
          loading: false,
        },
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'blog/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* getBlogByUuidSaga(body) {
  try {
    const result = yield call(getBlogByUuid, body)
    const { data } = result
    if (result.status === 200) {
      yield put({
        type: 'blog/SET_STATE',
        payload: {
          blogs: [],
          editBlog: data.data,
          isBlogCreated: false,
          isDeleted: false,
          isUpdated: false,
          uuid: null,
          loading: false,
        },
      })
    }
  } catch (err) {
    const key = `${Date.now()}`
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.`,
      key,
      btn: (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            notification.close(key)
            document.location.reload()
          }}
        >
          Reload
        </Button>
      ),
      duration: 0,
    })
    yield put({
      type: 'blog/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* deleteBlogByUuidSaga(payload) {
  try {
    const { uuid } = payload
    const result = yield call(deleteBlogByUuid, uuid)
    if (result.status === 200) {
      yield put({
        type: 'blog/SET_STATE',
        payload: {
          isDeleted: true,
          isBlogCreated: false,
          isUpdated: false,
          editBlog: '',
          uuid: null,
          loading: false,
        },
      })
      notification.success({
        message: 'Success',
        description: 'Blog is Deleted successfully',
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'blog/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* updateBlogSaga(payload) {
  try {
    yield put({
      type: 'blog/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const { body, uuid } = payload.payload
    const result = yield call(updateBlog, uuid, { data: body })
    if (result.status === 200) {
      yield put({
        type: 'blog/SET_STATE',
        payload: {
          isUpdated: true,
          isBlogCreated: false,
          isDeleted: false,
          editBlog: { ...body },
          uuid: null,
          loading: false,
        },
      })
      notification.success({
        message: 'Success',
        description: 'Blog is updated successfully',
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'blog/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* createScheduledBlogSaga({ payload }) {
  try {
    yield put({
      type: 'blog/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const result = yield call(createScheduledBlogApi, payload)
    if (result.status === 200) {
      notification.success({
        message: 'Success',
        description: 'Blog is created successfully',
      })
      yield put({
        type: 'blog/SET_STATE',
        payload: {
          isBlogCreated: true,
          isDeleted: false,
          isUpdated: false,
          blogs: [],
          uuid: result.data.data.uuid,
          loading: false,
        },
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'blog/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* getScheduledBlogListSaga(payload) {
  try {
    const { page } = payload
    const { date, status } = payload
    const result = yield call(getScheduledBlogList, page, date, status)
    const { data } = result
    if (result.status === 200) {
      yield put({
        type: 'blog/SET_STATE',
        payload: {
          blogs: data.data,
          totalBlogs: data.data.length,
          isBlogCreated: false,
          isDeleted: false,
          isUpdated: false,
          editBlog: '',
          uuid: null,
          loading: false,
        },
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'blog/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* getScheduledBlogByUuidSaga(body) {
  try {
    const result = yield call(getScheduledBlogByUuid, body)
    const { data } = result
    if (result.status === 200) {
      yield put({
        type: 'blog/SET_STATE',
        payload: {
          blogs: [],
          editBlog: data.data,
          isBlogCreated: false,
          isDeleted: false,
          isUpdated: false,
          uuid: null,
          loading: false,
        },
      })
    }
  } catch (err) {
    const key = `${Date.now()}`
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.`,
      key,
      btn: (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            notification.close(key)
            document.location.reload()
          }}
        >
          Reload
        </Button>
      ),
      duration: 0,
    })
    yield put({
      type: 'blog/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* deleteScheduledBlogByUuidSaga(payload) {
  try {
    const { uuid } = payload
    const result = yield call(deleteScheduledBlogByUuid, uuid)
    if (result.status === 200) {
      yield put({
        type: 'blog/SET_STATE',
        payload: {
          isDeleted: true,
          isBlogCreated: false,
          isUpdated: false,
          editBlog: '',
          uuid: null,
          loading: false,
        },
      })
      notification.success({
        message: 'Success',
        description: 'Blog is Deleted successfully',
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'blog/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* updateScheduledBlogSaga(payload) {
  try {
    yield put({
      type: 'blog/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const { body, uuid } = payload.payload
    const result = yield call(updateScheduledBlog, uuid, { data: body })
    if (result.status === 200) {
      yield put({
        type: 'blog/SET_STATE',
        payload: {
          isUpdated: true,
          isBlogCreated: false,
          isDeleted: false,
          editBlog: { ...body },
          uuid: null,
          loading: false,
        },
      })
      notification.success({
        message: 'Success',
        description: 'Blog is updated successfully',
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'blog/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_BLOG, createBlogSaga),
    takeEvery(actions.GET_LIST, getBlogListSaga),
    takeEvery(actions.GET_BLOG_BY_ID, getBlogByUuidSaga),
    takeEvery(actions.DELETE_BLOG_BY_ID, deleteBlogByUuidSaga),
    takeEvery(actions.UPDATE_BLOG, updateBlogSaga),
    takeEvery(actions.CREATE_SCHEDULED_BLOG, createScheduledBlogSaga),
    takeEvery(actions.GET_SCHEDULED_LIST, getScheduledBlogListSaga),
    takeEvery(actions.GET_SCHEDULED_BLOG_BY_ID, getScheduledBlogByUuidSaga),
    takeEvery(actions.DELETE_SCHEDULED_BLOG_BY_ID, deleteScheduledBlogByUuidSaga),
    takeEvery(actions.UPDATE_SCHEDULED_BLOG, updateScheduledBlogSaga),
  ])
}
