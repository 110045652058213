import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import {
  getUsersList,
  getUserByUuid,
  updateSadhanaSheetEnable,
  getAdminUsersList,
  getDiscipleData,
  verifyUser,
  pushPerson,
  getInsights,
  getMediaInsights,
} from 'services/userProfile'
import actions from './action'

export function* getUsersListSaga(payload) {
  try {
    const result = yield call(getUsersList, payload)

    const { data } = result

    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          users: data.data,
          total: data.meta.total,
          discipleData: null,
        },
      })
    }
  } catch (err) {
    console.log(err)
    notification.warning({
      message: 'Error',
      description: 'Some Error Occured',
    })
  }
}

export function* getUserByUuidSaga(body) {
  try {
    const result = yield call(getUserByUuid, body)

    const { data } = result
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          userDetails: data.userDetails,
          discipleData: null,
        },
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: 'Error Occured while getting Lecture',
    })
  }
}

export function* updateSadhanaSheetEnableSaga(body) {
  try {
    const obj = {
      user_id: body.user_id,
      recordType: 'userRecord',
      sadhanaSheetTable: body.sadhanaSheetTable,
    }
    const result = yield call(updateSadhanaSheetEnable, { data: obj })

    const { data } = result
    if (result.status === 200) {
      notification.success({
        message: 'Success',
        description: 'Sadhana Sheet Status updated successfully',
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          userDetails: data.userDetails,
          discipleData: null,
        },
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: 'Error Occured while while updating',
    })
  }
}

export function* getAdminUsersListSaga(payload) {
  try {
    const result = yield call(getAdminUsersList, payload)

    const { data } = result

    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          adminUsers: data.data.Users,
          discipleData: null,
        },
      })
    }
  } catch (err) {
    console.log(err)
    notification.warning({
      message: 'Error',
      description: 'Some Error Occured',
    })
  }
}

export function* getDiscipleDataSaga(payload) {
  try {
    const result = yield call(getDiscipleData, payload)

    const { data } = result

    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          discipleData: data.data,
        },
      })
    }
  } catch (err) {
    console.log(err)
    notification.warning({
      message: 'Error',
      description: 'Some Error Occured',
    })
  }
}

export function* verifyUserSaga(payload) {
  try {
    const result = yield call(verifyUser, payload)

    const { data } = result

    if (result.status === 200) {
      notification.success({
        message: 'Success',
        description: `User verified with Global Id ${payload.globalId}`,
      })
      const users = payload.users
      users.map((user) => {
        if (user.user_id === payload.userId) {
          user.idGlobal = payload.globalId
          user.verificationStatus = 'verified'
        }
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          discipleData: null,
          users: users,
        },
      })
    }
  } catch (err) {
    console.log(err)
    notification.warning({
      message: 'Error',
      description: 'Some Error Occured',
    })
  }
}

export function* pushPersonSaga(payload) {
  try {
    const result = yield call(pushPerson, payload)
    const { data } = result

    if (result.status === 200) {
      notification.success({
        message: 'Success',
        description: `Push Person Successful and User verified with Global Id ${data.idGlobal}`,
      })
      const users = payload.users
      users.map((user) => {
        if (user.user_id === payload.user_id) {
          user.idGlobal = data.idGlobal
          user.verificationStatus = 'verified'
        }
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          discipleData: null,
          users: users,
        },
      })
    }
  } catch (err) {
    console.log(err)
    notification.warning({
      message: 'Error',
      description: 'Some Error Occured',
    })
  }
}

export function* getInsightsSaga(payload) {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
      },
    })
    const result = yield call(getInsights, payload)

    const { data } = result

    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          userActivity: data.data,
          total: data.meta.total,
          loading: false,
        },
      })
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      })
      notification.warning({
        message: 'Error',
        description: 'Some Error Occured',
      })
    }
  } catch (err) {
    console.log(err)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 'Error',
      description: 'Some Error Occured',
    })
  }
}

export function* getMediaInsightsSaga(payload) {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
      },
    })
    const result = yield call(getMediaInsights, payload)

    const { data } = result

    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          mediaActivity: data.data,
          total: data.meta.total,
          loading: false,
        },
      })
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      })
      notification.warning({
        message: 'Error',
        description: 'Some Error Occured',
      })
    }
  } catch (err) {
    console.log(err)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 'Error',
      description: 'Some Error Occured',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_USERS, getUsersListSaga),
    takeEvery(actions.GET_ADMIN_USERS, getAdminUsersListSaga),
    takeEvery(actions.GET_USER_BY_ID, getUserByUuidSaga),
    takeEvery(actions.SADHANA_SHEET_ENABLE, updateSadhanaSheetEnableSaga),
    takeEvery(actions.GET_DISCIPLE_DATA, getDiscipleDataSaga),
    takeEvery(actions.VERIFY_USER, verifyUserSaga),
    takeEvery(actions.PUSH_PERSON, pushPersonSaga),
    takeEvery(actions.GET_INSIGHTS, getInsightsSaga),
    takeEvery(actions.GET_MEDIA_INSIGHTS, getMediaInsightsSaga),
  ])
}
