import axios from 'axios'
import { serverAddress } from './config'
import { getToken } from './awsAmplify'

export async function sendNotification(body) {
  try {
    const token = await getToken()
    const url = `${serverAddress}/api/notification/send`
    return axios.post(url, body, { headers: { Authorization: token } })
  } catch (err) {
    return err
  }
}
