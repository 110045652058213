const actions = {
  SET_STATE: 'user/SET_STATE',
  SET_NEW_PASSWORD_REQUEST: 'user/SET_NEW_PASSWORD_REQUEST',
  SET_NEW_PASSWORD: 'user/SET_NEW_PASSWORD',
  LOGIN: 'user/LOGIN',
  LOGIN_SUCCESS: 'user/LOGIN_SUCCESS',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  REQUEST_OTP: 'user/REQUEST_OTP',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
}

export default actions
