import React from 'react'
import { notification, Button } from 'antd'
import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects'
import {
  getLectureList,
  createLecture,
  deleteLectureByUuid,
  updateLecture,
  getLectureByUuid,
  getAudioClipList,
} from 'services/lecture'

import { getTopicList, getEventList, getLocationList, getKeywords } from 'services/common'
import actions from './action'

export function* getLectureListSaga(payload) {
  try {
    yield put({
      type: 'lecture/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const {
      page,
      date,
      createdDateSort,
      author,
      topic,
      year,
      language,
      location,
      event,
      translation,
      canto,
      chapter,
      verse,
    } = payload
    const result = yield call(
      getLectureList,
      page,
      date,
      createdDateSort,
      author,
      topic,
      year,
      language,
      location,
      event,
      translation,
      canto,
      chapter,
      verse,
    )
    const { data } = result
    if (result.status === 200) {
      yield put({
        type: 'lecture/SET_STATE',
        payload: {
          lectures: data.data,
          totalLectures: data.meta.total,
          editLecture: '',
          isLectureCreated: false,
          isDeleted: false,
          isUpdated: false,
          uuid: null,
          loading: false,
        },
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'lecture/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* createLectureSaga(payload) {
  try {
    yield put({
      type: 'lecture/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const { body } = payload
    const result = yield call(createLecture, body)
    if (result.status === 200) {
      notification.success({
        message: 'Success',
        description: 'Lecture is created successfully',
      })
      yield put({
        type: 'lecture/SET_STATE',
        payload: {
          isLectureCreated: true,
          isDeleted: false,
          isUpdated: false,
          lectures: [],
          uuid: result.data.data.uuid,
          loading: false,
        },
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'lecture/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* deleteBlogByUuidSaga(payload) {
  try {
    const { uuid, author } = payload
    const result = yield call(deleteLectureByUuid, uuid, author)
    if (result.status === 200) {
      yield put({
        type: 'lecture/SET_STATE',
        payload: {
          editLecture: '',
          isDeleted: true,
          isLectureCreated: false,
          isUpdated: false,
          uuid: null,
          loading: false,
        },
      })
      notification.success({
        message: 'Success',
        description: 'lecture is Deleted successfully',
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'lecture/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* updateLectureSaga(payload) {
  try {
    yield put({
      type: 'lecture/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const { body, uuid } = payload.payload
    console.log('payload ====>>>>', body)
    const result = yield call(updateLecture, { data: body })
    if (result.status === 200) {
      yield put({
        type: 'lecture/SET_STATE',
        payload: {
          isUpdated: true,
          isLectureCreated: false,
          isDeleted: false,
          uuid: null,
          loading: false,
        },
      })
      notification.success({
        message: 'Success',
        description: 'lecture is updated successfully',
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'lecture/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* getKeywordsSaga(payload) {
  try {
    const result = yield call(
      getKeywords,
      payload.payload.type !== 'all' ? payload.payload.type : null,
    )
    const { data } = result.data
    if (result.status === 200) {
      console.log('getKeywordsSaga>>>>>>>', data)
      Object.keys(data).map((key) => {
        data[key].sort((a, b) => {
          if (a.en < b.en) {
            return -1
          }
          if (a.en > b.en) {
            return 1
          }
          return 0
        })
      })
      if (payload.payload.origin && payload.payload.origin === 'taxonomy') {
        if (payload.payload.type === 'all') {
          yield put({
            type: 'taxonomy/SET_STATE',
            payload: {
              ...data,
              isCreated: false,
              isDeleted: false,
              loading: false,
              isUpdated: false,
            },
          })
        } else {
          yield put({
            type: 'taxonomy/SET_STATE',
            payload: {
              ...data,
              isCreated: false,
              isDeleted: false,
              loading: false,
              isUpdated: false,
            },
          })
        }
      } else {
        yield put({
          type: 'lecture/SET_STATE',
          payload: {
            topics:
              payload.payload.origin === 'quote' && data.QuoteTopic
                ? data.QuoteTopic
                : data.LectureTopic
                ? data.LectureTopic
                : [],
            events: data.Event || [],
            locations: data.Location || [],
            authors:
              payload.payload.type === 'quote'
                ? data.QuoteAuthor
                  ? data.QuoteAuthor
                  : []
                : data['Artist/Author']
                ? data['Artist/Author']
                : [],
            service: data.Service || [],
            gallery: data.Gallery || [],
            translation: data.Translation || [],
            tags: data.Tags || [],
            spLocation: data.spLocation || [],
            spLectureTopic: data.spLectureTopic || [],
          },
        })
        yield put({
          type: 'galleryListing/SET_STATE',
          payload: {
            isCreated: false,
            isDeleted: false,
            loading: false,
          },
        })
      }
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'lecture/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* getEvents() {
  try {
    const result = yield call(getEventList)
    const { data } = result
    if (result.status === 200) {
      yield put({
        type: 'lecture/SET_STATE',
        payload: {
          events: data.event,
          loading: false,
        },
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: 'Error Occured while getting Events',
    })
  }
}

export function* getLocations() {
  try {
    const result = yield call(getLocationList)
    const { data } = result
    if (result.status === 200) {
      yield put({
        type: 'lecture/SET_STATE',
        payload: {
          locations: data.location,
          loading: false,
        },
      })
    }
  } catch (err) {
    notification.error({
      message: 'Error',
      description: 'Error Occured while getting Locations',
    })
  }
}

export function* getLectureByUuidSaga(body) {
  try {
    const result = yield call(getLectureByUuid, body)
    console.log('result =====>>>>', result)
    const { data } = result
    if (result.status === 200) {
      yield put({
        type: 'lecture/SET_STATE',
        payload: {
          editLecture: data.data,
          isLectureCreated: false,
          isDeleted: false,
          isUpdated: false,
          uuid: null,
          loading: false,
        },
      })
    }
  } catch (err) {
    const key = `${Date.now()}`
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.`,
      key,
      btn: (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            notification.close(key)
            document.location.reload()
          }}
        >
          Reload
        </Button>
      ),
      duration: 0,
    })
    yield put({
      type: 'lecture/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* getAudioClipListSaga(payload) {
  try {
    const { page } = payload
    const { lectureId } = payload
    console.log(payload)
    const result = yield call(getAudioClipList, page, lectureId)
    console.log(result)
    const { data } = result
    if (result.status === 200) {
      yield put({
        type: 'lecture/SET_STATE',
        payload: {
          audioClips: data.data,
          uuid: null,
        },
      })
    }
  } catch (err) {
    console.log(err)
    notification.error({
      message: 'Error',
      description: `Some Error Occured. Please try again.\n Error: ${JSON.stringify(err)}`,
      duration: 0,
    })
    yield put({
      type: 'lecture/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_LECTURES, getLectureListSaga),
    takeLatest(actions.GET_KEYWORDS, getKeywordsSaga),
    takeEvery(actions.GET_EVENTS, getEvents),
    takeEvery(actions.GET_LOCATIONS, getLocations),
    takeEvery(actions.CREATE_LECTURE, createLectureSaga),
    takeEvery(actions.DELETE_LECTURE, deleteBlogByUuidSaga),
    takeEvery(actions.UPDATE_LECTURE, updateLectureSaga),
    takeEvery(actions.GET_LECTURE_BY_ID, getLectureByUuidSaga),
    takeEvery(actions.GET_AUDIO_CLIPS, getAudioClipListSaga),
  ])
}
