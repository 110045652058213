const actions = {
  SET_STATE: 'playlist/SET_STATE',
  CREATE_PLAYLIST: 'playlist/CREATE_PLAYLIST',
  GET_PLAYLIST: 'playlist/GET_PLAYLIST',
  GET_PLAYLIST_BY_ID: 'playlist/GET_PLAYLIST_BY_ID',
  DELETE_PLAYLIST_BY_ID: 'playlist/DELETE_PLAYLIST_BY_ID',
  RESET_STATE: 'playlist/RESET_STATE',
}

export default actions
