const actions = {
  SET_STATE: 'userProfile/SET_STATE',
  GET_USERS: 'userProfile/GET_USERS',
  GET_ADMIN_USERS: 'userProfile/GET_ADMIN_USERS',
  CREATE_ADMIN_USER: 'userProfile/CREATE_ADMIN_USER',
  GET_USER_BY_ID: 'userProfile/GET_USER_BY_ID',
  GET_USER_BY_SEARCH: 'userProfile/GET_USER_BY_SEARCH',
  SADHANA_SHEET_ENABLE: 'userProfile/SADHANA_SHEET_ENABLE',
  GET_DISCIPLE_DATA: 'userProfile/GET_DISCIPLE_DATA',
  VERIFY_USER: 'userProfile/VERIFY_USER',
  RESET_DISCIPLE_DATA: 'userProfile/RESET_DISCIPLE_DATA',
  PUSH_PERSON: 'userProfile/PUSH_PERSON',
  GET_INSIGHTS: 'userProfile/GET_INSIGHTS',
  GET_MEDIA_INSIGHTS: 'userProfile/GET_MEDIA_INSIGHTS',
}

export default actions
