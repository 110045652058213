const actions = {
  SET_STATE: 'quote/SET_STATE',
  GET_TOPICS: 'quote/GET_TOPICS',
  CREATE_QUOTE: 'quote/CREATE_QUOTE',
  GET_QUOTES: 'quote/GET_QUOTES',
  GET_QUOTE: 'quote/GET_QUOTE',
  CREATE_ERROR: 'quote/CREATE_ERROR',
  GET_LIST: 'quote/GET_LIST',
  GET_LIST_ERROR: 'quote/GET_LIST_ERROR',
  GET_QUOTE_BY_ID: 'quote/GET_QUOTE_BY_ID',
  GET_QUOTE_BY_ID_ERROR: 'quote/GET_QUOTE_BY_ID_ERROR',
  DELETE_QUOTE_BY_ID: 'quote/DELETE_QUOTE_BY_ID',
  UPDATE_QUOTE: 'quote/UPDATE_QUOTE',
  RESET_STATE: 'quote/RESET_STATE',
}

export default actions
