import axios from 'axios'
import { serverAddress, userIndex } from './config'
/* eslint-disable */

export async function getUsersList(payload) {
  const page = payload.page || 1
  const sort = payload.sort || null
  const { email } = payload
  let { disciple } = payload
  const { discipleName } = payload
  if (disciple === 3) {
    disciple = null
  }
  console.log(disciple)
  let url = ''
  if (!disciple && disciple !== 0 && !email) {
    url = `${serverAddress}/api/user/list?page=${page}`
    if (sort) {
      url = `${serverAddress}/api/user/list?page=${page}&sort=${sort}`
    }
    console.log(url)
  } else if ((!!disciple || disciple === 0) && !email) {
    url = `${serverAddress}/api/user/list?disciple=${disciple}&page=${page}`
    console.log(url)
  } else if (!!email && !disciple) {
    url = `${serverAddress}/api/search?fields=email&indexes=${userIndex}&q=${email}&page=${page}`
    console.log(url)
  } else if (!!email && !!disciple) {
    url = `${serverAddress}/api/search?fields=email&indexes=${userIndex}&q=${email}&disciple=${disciple}&page=${page}`
    console.log(url)
  }
  return axios
    .get(url)
    .then((response) => {
      if (response && response.data) {
        return response
      }
      return false
    })
    .catch((error) => {
      return error
    })
}

export async function getUserByUuid(request) {
  const body = request.payload
  const url = `${serverAddress}/api/user/getUserByUserId`
  return axios
    .post(url, body)
    .then((response) => {
      if (response.status === 200) {
        return response
      }
      return false
    })
    .catch((error) => {
      return error
    })
}

export async function updateSadhanaSheetEnable(payload) {
  const body = payload
  const url = `${serverAddress}/api/user/update`
  return axios
    .post(url, body)
    .then((response) => {
      if (response.status === 200) {
        return response
      }
      return false
    })
    .catch((error) => {
      return error
    })
}

export async function getAdminUsersList(payload) {
  const url = `${serverAddress}/api/user/admin/list`

  return axios
    .get(url)
    .then((response) => {
      if (response && response.data) {
        return response
      }
      return false
    })
    .catch((error) => {
      return error
    })
}

export async function getDiscipleData(payload) {
  const url = `${serverAddress}/api/user/getDiscipleData/${payload.uuid}`

  return axios
    .get(url)
    .then((response) => {
      if (response && response.data) {
        return response
      }
      return false
    })
    .catch((error) => {
      return error
    })
}

export async function verifyUser(payload) {
  const url = `${serverAddress}/api/user/verifyUser`
  const body = {
    userId: payload.userId,
    globalId: payload.globalId,
  }
  return axios
    .post(url, body)
    .then((response) => {
      if (response && response.data) {
        return response
      }
      return false
    })
    .catch((error) => {
      return error
    })
}

export async function pushPerson(payload) {
  const url = `${serverAddress}/api/user/pushPersonAndVerify`
  const body = {
    user_id: payload.user_id,
    verifier: payload.verifier,
  }
  return axios
    .post(url, body)
    .then((response) => {
      if (response && response.data) {
        return response
      }
      return false
    })
    .catch((error) => {
      return error
    })
}

export async function getInsights(payload) {
  const page = payload.page || 1
  const { email } = payload
  let { disciple } = payload
  if (disciple === 3) {
    disciple = null
  }
  let url = `${serverAddress}/api/insights/agglist?page=${page}`
  // if (!disciple && disciple !== 0 && !email) {
  //   url = `${serverAddress}/api/insights/list?page=${page}`
  //   console.log(url)
  // } else if ((!!disciple || disciple === 0) && !email) {
  //   url = `${serverAddress}/api/insights/list?disciple=${disciple}&page=${page}`
  //   console.log(url)
  // }
  return axios
    .get(url)
    .then((response) => {
      if (response && response.data) {
        return response
      }
      return false
    })
    .catch((error) => {
      return error
    })
}

export async function getMediaInsights(payload) {
  const page = payload.page || 1
  const { field, resource, action, sort } = payload

  let url = `${serverAddress}/api/insights/agglist?page=${page}&field=${field}&resource=${resource}&sort=${
    sort ? sort : 'timestamp'
  }`
  // if (!disciple && disciple !== 0 && !email) {
  //   url = `${serverAddress}/api/insights/list?page=${page}`
  //   console.log(url)
  // } else if ((!!disciple || disciple === 0) && !email) {
  //   url = `${serverAddress}/api/insights/list?disciple=${disciple}&page=${page}`
  //   console.log(url)
  // }
  return axios
    .get(url)
    .then((response) => {
      if (response && response.data) {
        return response
      }
      return false
    })
    .catch((error) => {
      return error
    })
}
