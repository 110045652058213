const actions = {
  SET_STATE: 'taxonomy/SET_STATE',
  CREATE_TAXONOMY: 'taxonomy/CREATE_TAXONOMY',
  GET_TAXONOMY_LIST: 'taxonomy/GET_TAXONOMY_LIST',
  REMOVE_TAXONOMY: 'taxonomy/REMOVE_TAXONOMY',
  SEARCH_TAXONOMY: 'taxonomy/SEARCH_TAXONOMY',
  EDIT_TAXONOMY: 'taxonomy/EDIT_TAXONOMY',
}

export default actions
